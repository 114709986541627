import { getPrice } from "../service/getPrice";

export const handleTokenPrice = async () => {
  try {
    const storedPrice = sessionStorage.getItem("priceURL");

    if (storedPrice) {
      return window.open(storedPrice, "_blank");
    } else {
      const response_price = await getPrice();

      if (response_price?.data?.msg === "Token invalid") {
        sessionStorage.clear();
      }

      if (response_price.length > 0) {
        const priceURL = response_price;
        sessionStorage.setItem("priceURL", priceURL);
        window.open(priceURL, "_blank");
      }
    }
  } catch (error) {
    console.error("Error al obtener el url price:", error);
  }
};
